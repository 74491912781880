import $ from '$';
import { PageSectionSelector } from '../../components';

class Schedule {
    fixGridColumns() {
        const grid = $('#SchedulesGrid').data('kendoGrid');
        grid.autoFitColumn(0);
    }

    init() {
        new PageSectionSelector({
            sectionsOrder: ['trainingDetails', 'scheduleList']
        });

        this.fixGridColumns();
    }
}

export default new Schedule();
