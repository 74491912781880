import $ from '$';
import { Swimlane, Drawer } from '../../components';

class Appshell {
    initMasthead() {
        const appnav = new Drawer('.pf-drawer-appnav');
        new Swimlane('.pf-drawer-appnav .pf-swimlane');
        $('.pf-masthead__right .pf-masthead__nav-btn').on(
            'click',
            appnav.toggle
        );
    }
    initSuiteNav() {
        const suitenav = new Drawer('.pf-suitemenu-drawer');
        $(
            '.pf-masthead__left .pf-masthead__nav-btn, .pf-accountmenu__close-btn'
        ).on('click', suitenav.toggle);
        $('.pf-accountmenu__toggle-btn').on(
            'click',
            function toggleAccountMenu() {
                const $elem = $('.pf-accountmenu__toggle-btn');
                const isOpen = $elem.attr('aria-pressed') === 'true';

                $elem.attr('aria-pressed', !isOpen);
                $('.pf-accountmenu__nav').attr('aria-hidden', isOpen);

                $elem
                    .find('.pf-accountmenu__toggle')
                    .toggleClass(
                        'pf-accountmenu__toggle_down pf-accountmenu__toggle_up'
                    );
            }
        );
    }
    init() {
        this.initMasthead();
        this.initSuiteNav();
    }
}

export default new Appshell();
