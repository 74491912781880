import $ from '$';

class PageSectionSelector {
    constructor(config) {
        this.config = config;
        this.handleSectionSelected = this.handleSectionSelected.bind(this);
        this.init();
    }
    getPageSections() {
        const pageSections = [];
        const pageSectionsElements = this.$getSectionsByGroupId('*');
        const processedPageSections = {};
        for (let i = 0, length = pageSectionsElements.length; i < length; i++) {
            const section = $(pageSectionsElements[i]);
            const sectionGroupId = section.data('sectiongroup-id');

            if (!processedPageSections[sectionGroupId]) {
                processedPageSections[sectionGroupId] = {
                    id: sectionGroupId,
                    Value: sectionGroupId,
                    Name: section.data('sectiongroup-name')
                };
                if (!this.config || !this.config.sectionsOrder) {
                    pageSections.push(processedPageSections[sectionGroupId]);
                }
            }
        }

        if (this.config && this.config.sectionsOrder) {
            for (
                let i = 0, length = this.config.sectionsOrder.length;
                i < length;
                i++
            ) {
                const sectionGroupId = this.config.sectionsOrder[i];
                const sectionInfo = processedPageSections[sectionGroupId];
                if (sectionInfo) {
                    pageSections.push(sectionInfo);
                }
            }
        }

        return pageSections;
    }
    init() {
        this.pageSections = this.getPageSections();
        if (!this.pageSections[0]) {
            // if no sections defined get out
            return;
        }

        // determine section selector
        if (this.config && this.config.sectionSelector) {
            this.selector = this.config.sectionSelector;
            this.selector.setDataSource(this.pageSections);
            this.selector.bind('select', this.handleSectionSelected);
        } else {
            let selectorElement = $('.lms-page__section-selector');
            if (!selectorElement.length) {
                selectorElement = $(
                    '<span class="lms-page__section-selector"></span>'
                ).prependTo('.pf-page__content');
            }

            this.selector = selectorElement
                .kendoDropDownList({
                    dataTextField: 'Name',
                    dataValueField: 'Value',
                    select: this.handleSectionSelected,
                    dataSource: {
                        data: this.pageSections
                    }
                })
                .getKendoDropDownList();
        }

        this.dataSource = this.selector.dataSource;
        this.setInitialVisiblePageSection();
    }
    setInitialVisiblePageSection() {
        let initialVisibleSectionId = this.getStoredSectionId();
        initialVisibleSectionId =
            initialVisibleSectionId &&
            this.dataSource.get(initialVisibleSectionId)
                ? initialVisibleSectionId
                : this.dataSource.view()[0].Value;

        this.selector.value(initialVisibleSectionId);
        this.setVisibleSection(initialVisibleSectionId);
    }
    getSectionIdKey() {
        return 'visiblePageSection';
    }
    $getSectionsByGroupId(sectionGroupId) {
        const query =
            sectionGroupId != '*'
                ? '[data-sectiongroup-id="' + sectionGroupId + '"]'
                : 'section[data-sectiongroup-id]';
        return $(query);
    }
    setVisibleSection(sectionId, previousSectionId) {
        if (previousSectionId) {
            this.$getSectionsByGroupId(previousSectionId).removeClass(
                'lms-page__section-group_visible'
            );
        }

        this.$getSectionsByGroupId(sectionId).addClass(
            'lms-page__section-group_visible'
        );
        this.storeVisibleSectionData(sectionId);
    }
    handleSectionSelected(e) {
        const previousSection = e.sender.value();
        const newSection = e.dataItem.Value;

        if (newSection != previousSection) {
            this.setVisibleSection(newSection, previousSection);
        }
    }
    storeVisibleSectionData(sectionId) {
        const sectionData = {};
        sectionData[document.location.pathname] = sectionId;
        sessionStorage.setItem(
            this.getSectionIdKey(),
            JSON.stringify(sectionData)
        );
    }
    getStoredSectionId() {
        const sectionData = sessionStorage.getItem(this.getSectionIdKey());
        let sectionId = '';
        try {
            sectionId =
                sectionData &&
                JSON.parse(sectionData)[document.location.pathname];
        } catch (e) {
            // do nothing - this should never happen - just in case that section id was already stored in other format
        }
        return sectionId;
    }
}

export default PageSectionSelector;
