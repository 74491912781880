import { PageSectionSelector } from '../../components';

class MyTraining {
    init() {
        new PageSectionSelector({
            sectionsOrder: [
                'pendingApprovals',
                'currentTraining',
                'trainingHistory'
            ]
        });
    }
}

export default new MyTraining();
