import $ from '$';

const DEFAULT_CSS_CLASS = 'lms-collapse';

class Collapse {
    get state() {
        return this.o;
    }

    set state(o) {
        this.o = o;
    }

    constructor(selector) {
        this.o = {
            open: true
        };
        this.$me = $(selector);
        this.header = this.$me.find(`.${DEFAULT_CSS_CLASS}__header`).attr({
            role: 'button',
            tabindex: 0
        });
        this.collapsibleContent = this.$me.find(
            `.${DEFAULT_CSS_CLASS}__content`
        );
        this.contentBody = this.$me.find(`.${DEFAULT_CSS_CLASS}__body`);

        this.toggle = this.toggle.bind(this);
        this.resetHeight = this.resetHeight.bind(this);
        this.handleKeyDown = this.handleKeyDown.bind(this);

        this.header.on('click', this.toggle).on('keydown', this.handleKeyDown);
    }

    resetHeight() {
        this.state.open ? this.collapsibleContent.height('auto') : null;
    }

    handleKeyDown(e) {
        if (e.keyCode === 13 || e.keyCode === 32) {
            e.preventDefault();
            this.toggle();
        }
    }

    toggle() {
        const isOpen = this.state.open;
        this.state = { open: !isOpen };
        this.$me.toggleClass(`${DEFAULT_CSS_CLASS}_closed`, isOpen);
        this.collapsibleContent.attr('aria-hidden', isOpen).slideToggle({
            duration: 200,
            complete: this.resetHeight()
        });
    }
}

export default Collapse;
