import $ from '$';

const DEFAULT_CSS_CLASS = 'pf-drawer';

class Drawer {
    get state() {
        return this.o;
    }

    set state(o) {
        this.o = o;
    }

    constructor(selector) {
        this.o = {
            open: false
        };
        this.$me = $(selector);
        this.overlay = $(`${selector}__overlay`);
        this.closeBtn = this.$me.find(`.${DEFAULT_CSS_CLASS}__close-btn`);
        this.toggle = this.toggle.bind(this);

        this.overlay.on('click keydown', this.toggle);
        this.closeBtn.on('click', this.toggle);
    }

    toggle() {
        const isOpen = this.state.open;
        this.$me.toggleClass(`${DEFAULT_CSS_CLASS}_open`, !isOpen);
        this.overlay.attr('aria-hidden', isOpen);
        this.state = { open: !isOpen };
    }
}

export default Drawer;
