import $ from '$';
import { getElemScrollLeftRight } from '@peoplefluent/ui/src/components/Swimlane/utils';

const DEFAULT_CSS_CLASS = 'pf-swimlane';

class Swimlane {
    get state() {
        return this.o;
    }

    set state(o) {
        this.update(o);
    }

    constructor(selector) {
        this.o = {
            menubarScroll: {
                left: 0, // The left usually starts from 0
                right: false // Right would be updated on first mount
            }
        };
        this.$me = $(selector);
        this.$leftButton = this.$me.find(
            `.${DEFAULT_CSS_CLASS}__scroll-btn_left`
        );
        this.$rightButton = this.$me.find(
            `.${DEFAULT_CSS_CLASS}__scroll-btn_right`
        );
        this.swimlane = this.$me.find(`.${DEFAULT_CSS_CLASS}__lane`);
        this.scroller = this.$me.find(`.${DEFAULT_CSS_CLASS}__scroller`);
        this.handleMenuScroll = this.handleMenuScroll.bind(this);
        this.handleScrollButtons = this.handleScrollButtons.bind(this);

        this.handleMenuScroll(); // Make sure arrows are visible as required on mount

        // Event handlers
        this.$leftButton.on('click', this.handleScrollButtons);
        this.$rightButton.on('click', this.handleScrollButtons);
        this.scroller.on('scroll', this.handleMenuScroll);
        window.addEventListener('resize', this.handleMenuScroll);
        this.scrollToCurrentMenuItem();
    }

    destroy() {
        window.removeEventListener('resize', this.handleMenuScroll);
        this.$me.remove();
    }

    update(state) {
        this.o.menubarScroll = state;
        this.$leftButton.prop('disabled', !state.left);
        this.$rightButton.prop('disabled', !state.right);
    }

    scrollToCurrentMenuItem() {
        this._scrollToCurrentMenuItem(
            this.swimlane.get(0),
            this.scroller.get(0),
            ['[aria-current="true"]']
        );
    }

    _scrollToCurrentMenuItem(swimlane, scroller, currentItemSelector) {
        const currentItem = swimlane.querySelector(currentItemSelector);

        // Scroll the item to the mid of the swimlane
        if (currentItem) {
            scroller.scrollLeft =
                currentItem.offsetLeft -
                scroller.offsetLeft -
                // Use Math.ciel (vs. parseInt) to avoid subpixel rendering issues
                Math.ceil(scroller.clientWidth / 2) +
                Math.ceil(currentItem.clientWidth / 2);
        }
    }

    scrollJumpX(direction) {
        this._scrollJumpX(this.scroller.get(0), direction);
    }

    _scrollJumpX(element, direction) {
        const jumpFactor = parseInt(direction, 10);

        // Jump to the next snap-point just outside the lane visible area
        element.scrollLeft =
            this.state.menubarScroll.left + jumpFactor * element.clientWidth;
    }

    handleMenuScroll() {
        this.state = getElemScrollLeftRight(this.scroller.get(0));
    }

    handleScrollButtons(e) {
        const button = e.currentTarget;

        switch (button.name) {
            case '_lane-button-right':
                this.scrollJumpX(1);
                break;
            case '_lane-button-left':
                this.scrollJumpX(-1);
                break;
            default: // do nothing
        }
    }
}

export default Swimlane;
