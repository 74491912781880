import $ from '$';
import Collapse from '../../components/Collapse';

class Home {
    init() {
        $('.lms-collapse').each((i, elem) => {
            const collapseContainer = new Collapse(elem);
            if ($(window).width() <= 576) {
                collapseContainer.toggle();
            }
        });
    }
}

export default new Home();
