import $ from '$';

class Catalog {
    constructor() {
        this.handleFilterClick = this.handleFilterClick.bind(this);
        this.handleCloseFilter = this.handleCloseFilter.bind(this);
    }
    toggleFilter(isOpen) {
        $('.lms-filter__section').toggleClass(
            'lms-filter__section_open',
            isOpen
        );
    }
    handleFilterClick(e) {
        e.preventDefault();
        this.toggleFilter(true);
    }
    handleCloseFilter(e) {
        e.preventDefault();
        this.toggleFilter(false);
    }
    init() {
        $('.lms-trainingcatalog__quicksearch-buttons').on(
            'click',
            '.lms-trainingcatalog__btn-filter',
            this.handleFilterClick
        );
        $('.lms-filter__sectionclose').on('click', this.handleCloseFilter);
    }
}

export default new Catalog();
